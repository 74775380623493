import React from "react"

import blueGrid from "../../../images/grids/life-grid-blue-min.png"
import grayGrid from "../../../images/grids/life-grid-gray-min.png"
import grayBlueGrid from "../../../images/grids/life-grid-gray-blue-min.png"
import greenGrid from "../../../images/grids/life-grid-green-min.png"
import orangeYellowGrid from "../../../images/grids/life-grid-orange-yellow-min.png"
import pinkGrid from "../../../images/grids/life-grid-pink-min.png"
import royalBlueGrid from "../../../images/grids/life-grid-royal-blue-min.png"
import softBlueGrid from "../../../images/grids/life-grid-soft-blue-min.png"
import whiteGrid from "../../../images/grids/life-grid-white-min.png"
import yellowGrid from "../../../images/grids/life-grid-yellow-min.png"
import yellowGreenGrid from "../../../images/grids/life-grid-yellow-green-min.png"

export default function LifeGrid({ color }) {
  let bgImage = null

  if (color === "uow-blue") {
    bgImage = blueGrid
  }
  if (color === "uow-light-gray") {
    bgImage = grayGrid
  }
  if (color === "uow-gray-blue") {
    bgImage = grayBlueGrid
  }
  if (color === "uow-green") {
    bgImage = greenGrid
  }
  if (color === "uow-orange-yellow") {
    bgImage = orangeYellowGrid
  }
  if (color === "uow-pink") {
    bgImage = pinkGrid
  }
  if (color === "uow-royal-blue") {
    bgImage = royalBlueGrid
  }
  if (color === "uow-soft-blue") {
    bgImage = softBlueGrid
  }
  if (color === "uow-white") {
    bgImage = whiteGrid
  }
  if (color === "uow-yellow") {
    bgImage = yellowGrid
  }
  if (color === "uow-yellow-green") {
    bgImage = yellowGreenGrid
  }

  return (
    <div
      className="life-grid"
      style={{ backgroundImage: "url(" + bgImage + ")" }}
    />
  )
}
