export default theme => ({
  // eslint-disable-line
  iFrameWrap: {
    position: "relative",
    backgroundColor: "var(--page-accent)",
    "& h1": {
      zIndex: 2,
      marginBottom: 0,
      marginTop: 24,
      position: "relative",
      "-webkit-text-stroke-width": "1px",
      "-webkit-text-stroke-color": "var(--uow-white)",
      color: "transparent !important",
    },
    "& h2": {
      zIndex: 2,
      marginBottom: 10,
      position: "relative",
      fontWeight: "normal !important",
    },
    "& iframe": {
      border: "none",
      position: "relative",
      zIndex: 2,
      marginBottom: 60,
    },
    "& .life-grid": {
      content: '""',
      display: "block",
      position: "absolute",
      zIndex: 0,
      left: "50%",
      width: "100vw",
      transform: "translateX(-50%)",
      top: -100,
      bottom: 100,
      opacity: 0.33,
      mixBlendMode: "multiply",
      backgroundPosition: "center top",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      [theme.breakpoints.up("md")]: {
        top: -120,
      },
    },
    "&:after": {
      content: '""',
      display: "block",
      backgroundColor: "var(--uow-white)",
      position: "absolute",
      zIndex: 1,
      bottom: 0,
      left: -999,
      right: -999,
      height: 150,
      [theme.breakpoints.up("md")]: {
        height: 350,
      },
    },
  },
})
