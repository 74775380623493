import React from "react"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/styles"
import styles from "./styles"
import LifeGrid from "../Reusable/LifeGrid"

export default function MagazineIframe({ iframeUrl, name, color }) {
  const useStyles = makeStyles(theme => styles(theme, color))

  const classes = useStyles()

  return (
    <div className={`iframe-wrap ${classes.iFrameWrap} `}>
      <LifeGrid color={color} />
      <Typography component="h1" variant="h1">
        WLV Life
      </Typography>
      <Typography component="h2" variant="h2">
        Edition {name}
      </Typography>
      <iframe src={iframeUrl} width="100%" height="800" title="Edition PDF" />
    </div>
  )
}
