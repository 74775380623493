import React, { Component } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import MagazineIframe from "../components/MagazineIframe"

class Job extends Component {
  render() {
    const { name } = this.props.data.wpEdition

    this.props.pageContext.pageTitle = name
    this.props.pageContext.edition = this.props.data.wpEdition

    return (
      <Layout
        meta={this.props.data.wpEdition}
        path={this.props.pageContext.pagePath}
        title={name}
        themeOptions={this.props.data.wp.themeOptions}
        pageColour={this.props.data.wpEdition.acfPageColour.pageColour}
      >
        <MagazineIframe
          iframeUrl={this.props.data.wpEdition.Editions.iframeUrl}
          name={name}
          color={this.props.data.wpEdition.acfPageColour.pageColour}
        />
      </Layout>
    )
  }
}

export const query = graphql`
  query($id: String) {
    wpEdition(id: { eq: $id }) {
      id
      name
      uri
      Editions {
        iframeUrl
      }
      acfPageColour {
        pageColour
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`

export default Job
